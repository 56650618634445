import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Share from '../components/share'
import { unescapePunctuation } from '../utilities/formatters'

export const BreachTemplate = ({ title, content, path }) => {
  const contentParsed = content.replace(/http:\/\/18.235.35.120/g, "https://content.tfttpresents.com").replace(/http:\/\/admin.tfttpresents.com/g, "https://content.tfttpresents.com");
  return (
    <div className="page--breach">
      <section className="hero hero--breach">
        <h1 className="glitch" data-text="Breach">Breach</h1>
      </section>
      <article
        className="content"
        role="article"
        dangerouslySetInnerHTML={{ __html: contentParsed }}
      />

      <aside className="post__meta">
        <Share
          title={unescapePunctuation(title)}
          path={path}
        />
      </aside>
    </div>
  )
}

BreachTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
}

const Breach = ({ data }) => {
  const { wordpressPage: page } = data

  return (
    <Layout>
      <BreachTemplate title={page.title} content={page.content} path={page.path} />
    </Layout>
  )
}

Breach.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Breach

export const breachQuery = graphql`
  query BreachPageById($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      path
    }
  }
`
